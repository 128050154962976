import type { PaletteOptions } from "@mui/material";
import type { PaletteColorOptions, SimplePaletteColorOptions } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Palette {
    c2color: SimplePaletteColorOptions;
    c1color: SimplePaletteColorOptions;
    b2color: SimplePaletteColorOptions;
    b1color: SimplePaletteColorOptions;
    a2color: SimplePaletteColorOptions;
    a2fcolor: SimplePaletteColorOptions;
    a1mcolor: SimplePaletteColorOptions;
    pa1color: SimplePaletteColorOptions;
    ylecolor: SimplePaletteColorOptions;
    otherscolor: SimplePaletteColorOptions;
    backgroundHover: SimplePaletteColorOptions;
    cancelColor: SimplePaletteColorOptions;
    cancelBgColor: SimplePaletteColorOptions;
    activityCorrectionPendingColor: SimplePaletteColorOptions;
    levelsIconColor: SimplePaletteColorOptions;
    hamptonSurfColor: SimplePaletteColorOptions;
    kabachaBrownColor: SimplePaletteColorOptions;
    frostWindColor: SimplePaletteColorOptions;
    navyBlackColor: SimplePaletteColorOptions;
    algalFuelColor: SimplePaletteColorOptions;
    bottleGlassColor: SimplePaletteColorOptions;
    coralOrangeColor: SimplePaletteColorOptions;
    seaSaltSherbetColor: SimplePaletteColorOptions;
    coastalBreezeColor: SimplePaletteColorOptions;
    celloColor: SimplePaletteColorOptions;
    crunchyCarrotColor: SimplePaletteColorOptions;
    indianTealColor: SimplePaletteColorOptions;
    blueNileColor: SimplePaletteColorOptions;
    whiteUltraMarineColor: SimplePaletteColorOptions;
    azureishWhiteColor: SimplePaletteColorOptions;
    fogOfWarColor: SimplePaletteColorOptions;
    americanAnthemColor: SimplePaletteColorOptions;
    mortarGreyColor: SimplePaletteColorOptions;
    hawaiianShellColor: SimplePaletteColorOptions;
    grapeFruitColor: SimplePaletteColorOptions;
    brazilianiteColor: SimplePaletteColorOptions;
    celestialCathedralColor: SimplePaletteColorOptions;
    whiplashColor: SimplePaletteColorOptions;
    turfMasterColor: SimplePaletteColorOptions;
    mournMountainSnowColor: SimplePaletteColorOptions;
    afterMidnightColor: SimplePaletteColorOptions;
    verdigrisColouredColor: SimplePaletteColorOptions;
    petitePinkColor: SimplePaletteColorOptions;
    glowingBrakeDiscColor: SimplePaletteColorOptions;
    eliteTealColor: SimplePaletteColorOptions;
    lightSeaCliff: SimplePaletteColorOptions;
    seaDeepColor: SimplePaletteColorOptions;
    bluesyNoteColor: SimplePaletteColorOptions;
    serengetiGreenColor: SimplePaletteColorOptions;
    blizzardColor: SimplePaletteColorOptions;
    titaniumBlueColor: SimplePaletteColorOptions;
    fluorescentRedColor: SimplePaletteColorOptions;
    oceanViewColor: SimplePaletteColorOptions;
    eveningSunsetColor: SimplePaletteColorOptions;
    bluePerennialColor: SimplePaletteColorOptions;
    rubberRadishColor: SimplePaletteColorOptions;
    buddhasLoveHandlesColor: SimplePaletteColorOptions;
    fullOfLifeColor: SimplePaletteColorOptions;
    bigSurBlueJadeColor: SimplePaletteColorOptions;
    silverSkateColor: SimplePaletteColorOptions;
    waterColor: SimplePaletteColorOptions;
    blueLonelinessColor: SimplePaletteColorOptions;
    bicycleYellowColor: SimplePaletteColorOptions;
  }
  interface PaletteOptions {
    c2color: PaletteColorOptions;
    c1color: PaletteColorOptions;
    b2color: PaletteColorOptions;
    b1color: PaletteColorOptions;
    a2color: PaletteColorOptions;
    a2fcolor: PaletteColorOptions;
    a1mcolor: PaletteColorOptions;
    pa1color: PaletteColorOptions;
    ylecolor: PaletteColorOptions;
    otherscolor: PaletteColorOptions;
    userCard_addNew: PaletteColorOptions;
    backgroundHover: SimplePaletteColorOptions;
    cancelColor: PaletteColorOptions;
    cancelBgColor: PaletteColorOptions;
    activityCorrectionPendingColor: PaletteColorOptions;
    levelsIconColor: SimplePaletteColorOptions;
    hamptonSurfColor: SimplePaletteColorOptions;
    kabachaBrownColor: SimplePaletteColorOptions;
    frostWindColor: SimplePaletteColorOptions;
    navyBlackColor: SimplePaletteColorOptions;
    algalFuelColor: SimplePaletteColorOptions;
    bottleGlassColor: SimplePaletteColorOptions;
    coralOrangeColor: SimplePaletteColorOptions;
    seaSaltSherbetColor: SimplePaletteColorOptions;
    coastalBreezeColor: SimplePaletteColorOptions;
    celloColor: SimplePaletteColorOptions;
    crunchyCarrotColor: SimplePaletteColorOptions;
    indianTealColor: SimplePaletteColorOptions;
    blueNileColor: SimplePaletteColorOptions;
    whiteUltraMarineColor: SimplePaletteColorOptions;
    azureishWhiteColor: SimplePaletteColorOptions;
    fogOfWarColor: SimplePaletteColorOptions;
    americanAnthemColor: SimplePaletteColorOptions;
    mortarGreyColor: SimplePaletteColorOptions;
    hawaiianShellColor: SimplePaletteColorOptions;
    grapeFruitColor: SimplePaletteColorOptions;
    brazilianiteColor: SimplePaletteColorOptions;
    celestialCathedralColor: SimplePaletteColorOptions;
    whiplashColor: SimplePaletteColorOptions;
    turfMasterColor: SimplePaletteColorOptions;
    mournMountainSnowColor: SimplePaletteColorOptions;
    afterMidnightColor: SimplePaletteColorOptions;
    verdigrisColouredColor: SimplePaletteColorOptions;
    petitePinkColor: SimplePaletteColorOptions;
    glowingBrakeDiscColor: SimplePaletteColorOptions;
    eliteTealColor: SimplePaletteColorOptions;
    lightSeaCliff: SimplePaletteColorOptions;
    seaDeepColor: SimplePaletteColorOptions;
    bluesyNoteColor: SimplePaletteColorOptions;
    serengetiGreenColor: SimplePaletteColorOptions;
    blizzardColor: SimplePaletteColorOptions;
    titaniumBlueColor: SimplePaletteColorOptions;
    fluorescentRedColor: SimplePaletteColorOptions;
    oceanViewColor: SimplePaletteColorOptions;
    eveningSunsetColor: SimplePaletteColorOptions;
    bluePerennialColor: SimplePaletteColorOptions;
    rubberRadishColor: SimplePaletteColorOptions;
    buddhasLoveHandlesColor: SimplePaletteColorOptions;
    fullOfLifeColor: SimplePaletteColorOptions;
    bigSurBlueJadeColor: SimplePaletteColorOptions;
    silverSkateColor: SimplePaletteColorOptions;
    waterColor: SimplePaletteColorOptions;
    blueLonelinessColor: SimplePaletteColorOptions;
    bicycleYellowColor: SimplePaletteColorOptions;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    c2color: true;
    c1color: true;
    b2color: true;
    b1color: true;
    a2color: true;
    a2fcolor: true;
    a1mcolor: true;
    pa1color: true;
    ylecolor: true;
    otherscolor: true;
    backgroundHover: true;
    cancelColor: true;
    cancelBgColor: true;
    activityCorrectionPendingColor: true;
    levelsIconColor: true;
    hamptonSurfColor: true;
    kabachaBrownColor: true;
    frostWindColor: true;
    algalFuelColor: true;
    coralOrangeColor: true;
    seaSaltSherbetColor: true;
    coastalBreezeColor: true;
    celloColor: true;
    crunchyCarrotColor: true;
    indianTealColor: true;
    blueNileColor: true;
    whiteUltraMarineColor: true;
    azureishWhiteColor: true;
    fogOfWarColor: true;
    americanAnthemColor: true;
    mortarGreyColor: true;
    hawaiianShellColor: true;
    grapeFruitColor: true;
    brazilianiteColor: true;
    celestialCathedralColor: true;
    whiplashColor: true;
    turfMasterColor: true;
    mournMountainSnowColor: true;
    afterMidnightColor: true;
    verdigrisColouredColor: true;
    petitePinkColor: true;
    glowingBrakeDiscColor: true;
    eliteTealColor: true;
    lightSeaCliff: true;
    seaDeepColor: true;
    bluesyNoteColor: true;
    serengetiGreenColor: true;
    blizzardColor: true;
    titaniumBlueColor: true;
    fluorescentRedColor: true;
    oceanViewColor: true;
    eveningSunsetColor: true;
    bluePerennialColor: true;
    rubberRadishColor: true;
    buddhasLoveHandlesColor: true;
    fullOfLifeColor: true;
    bigSurBlueJadeColor: true;
    silverSkateColor: true;
    waterColor: true;
    blueLonelinessColor: true;
    bicycleYellowColor: true;
  }
}

export const lightModePalette: PaletteOptions = {
  primary: {
    main: "#3b505e",
    // light: "#f6685e",
    // dark: "#aa2e25",
  },
  secondary: {
    main: "#5e493b",
    // light: "#ffee33",
    // dark: "#b2a300",
  },
  userCard_addNew: {
    main: "#5d90ac",
  },
  c2color: {
    main: "#7d669c",
  },
  c1color: {
    main: "#34adcf",
  },
  b2color: {
    main: "#5bb866",
  },
  b1color: {
    main: "#E25257",
  },
  a2color: {
    main: "#50bbb4",
  },
  a2fcolor: {
    main: "#A5DD6D",
  },
  a1mcolor: {
    main: "#9F1D97",
  },
  pa1color: {
    main: "#F9A800",
  },
  ylecolor: {
    main: "#3079AB",
  },
  otherscolor: {
    main: "#EE9174",
  },
  background: {
    default: "#D5EBEF",
  },
  backgroundHover: {
    main: "#EFFBFD",
  },
  cancelColor: {
    main: "#EB8686",
  },
  cancelBgColor: {
    main: "#FFF0F0",
  },
  activityCorrectionPendingColor: {
    main: "#FFE7E7",
  },
  text: {
    primary: "#155380",
  },
  levelsIconColor: {
    main: "#2D507C",
  },
  hamptonSurfColor: {
    main: "#567480",
  },
  kabachaBrownColor: {
    main: "#B04E2F",
  },
  frostWindColor: {
    main: "#D7EAEE",
  },
  navyBlackColor: {
    main: "#243033",
  },
  algalFuelColor: {
    main: "#2BCE76",
  },
  bottleGlassColor: {
    main: "#1E8E52",
  },
  coralOrangeColor: {
    main: "#E7684C",
  },
  seaSaltSherbetColor: {
    main: "#FFF5F5",
  },
  coastalBreezeColor: {
    main: "#DFF3F8",
  },
  celloColor: {
    main: "#3A505E",
  },
  crunchyCarrotColor: {
    main: "#E95016",
  },
  indianTealColor: {
    main: "#3F5A69",
  },
  blueNileColor: {
    main: "#76A0B7",
  },
  whiteUltraMarineColor: {
    main: "#7FCCD6",
  },
  azureishWhiteColor: {
    main: "#D9E7F4",
  },
  fogOfWarColor: {
    main: "#0E2535",
  },
  americanAnthemColor: {
    main: "#7697AA",
  },
  mortarGreyColor: {
    main: "#9E9E9E",
  },
  hawaiianShellColor: {
    main: "#F2D9D9",
  },
  grapeFruitColor: {
    main: "#FD5A5A",
  },
  brazilianiteColor: {
    main: "#2ECA45",
  },
  celestialCathedralColor: {
    main: "#D8EAF7",
  },
  whiplashColor: {
    main: "#C74646",
  },
  turfMasterColor: {
    main: "#07981E",
  },
  mournMountainSnowColor: {
    main: "#E9E9EA",
  },
  afterMidnightColor: {
    main: "#39393D",
  },
  verdigrisColouredColor: {
    main: "#60BF75",
  },
  petitePinkColor: {
    main: "#EACDCC",
  },
  glowingBrakeDiscColor: {
    main: "#EB4141",
  },
  eliteTealColor: {
    main: "#113138",
  },
  lightSeaCliff: {
    main: "#B9D4E7",
  },
  seaDeepColor: {
    main: "#2D3D45",
  },
  bluesyNoteColor: {
    main: "#7D9EB9",
  },
  serengetiGreenColor: {
    main: "#72CE86",
  },
  blizzardColor: {
    main: "#E4EBEC",
  },
  titaniumBlueColor: {
    main: "#587991",
  },
  fluorescentRedColor: {
    main: "#FE5353",
  },
  oceanViewColor: {
    main: "#749CB4",
  },
  eveningSunsetColor: {
    main: "#EEB16E",
  },
  bluePerennialColor: {
    main: "#BDD8DE",
  },
  rubberRadishColor: {
    main: "#FF9C9C",
  },
  buddhasLoveHandlesColor: {
    main: "#FFB932",
  },
  fullOfLifeColor: {
    main: "#DC612D",
  },
  bigSurBlueJadeColor: {
    main: "#3D708D",
  },
  silverSkateColor: {
    main: "#88A1B5",
  },
  waterColor: {
    main: "#D9F1F9",
  },
  blueLonelinessColor: {
    main: "#486C87",
  },
  bicycleYellowColor: {
    main: "#FFE38D",
  },
};

export const darkModePalette: PaletteOptions = {
  primary: {
    main: "#0A1172",
    // light: "#63C5DA",
    // dark: "#3944BC",
  },
  secondary: {
    main: "#097969",
    // light: "#90EE90",
    // dark: "#00A36C",
  },
  userCard_addNew: {
    main: "#5d90ac",
  },
  c2color: {
    main: "#7d669c",
  },
  c1color: {
    main: "#34adcf",
  },
  b2color: {
    main: "#5bb866",
  },
  b1color: {
    main: "#E25257",
  },
  a2color: {
    main: "#50bbb4",
  },
  a2fcolor: {
    main: "#A5DD6D",
  },
  a1mcolor: {
    main: "#9F1D97",
  },
  pa1color: {
    main: "#F9A800",
  },
  ylecolor: {
    main: "#3079AB",
  },
  otherscolor: {
    main: "#EE9174",
  },
  background: {
    default: "rgb(233,240,244)",
  },
  backgroundHover: {
    main: "#EFFBFD",
  },
  cancelColor: {
    main: "#EB8686",
  },
  cancelBgColor: {
    main: "#FFF0F0",
  },
  activityCorrectionPendingColor: {
    main: "#FFE7E7",
  },
  levelsIconColor: {
    main: "#2D507C",
  },
  hamptonSurfColor: {
    main: "#567480",
  },
  kabachaBrownColor: {
    main: "#B04E2F",
  },
  frostWindColor: {
    main: "#D7EAEE",
  },
  navyBlackColor: {
    main: "#243033",
  },
  algalFuelColor: {
    main: "#2BCE76",
  },
  bottleGlassColor: {
    main: "#1E8E52",
  },
  coralOrangeColor: {
    main: "#E7684C",
  },
  seaSaltSherbetColor: {
    main: "#FFF5F5",
  },
  coastalBreezeColor: {
    main: "#DFF3F8",
  },
  celloColor: {
    main: "#3A505E",
  },
  crunchyCarrotColor: {
    main: "#E95016",
  },
  indianTealColor: {
    main: "#3F5A69",
  },
  blueNileColor: {
    main: "#76A0B7",
  },
  whiteUltraMarineColor: {
    main: "#7FCCD6",
  },
  azureishWhiteColor: {
    main: "#D9E7F4",
  },
  fogOfWarColor: {
    main: "#0E2535",
  },
  americanAnthemColor: {
    main: "#7697AA",
  },
  mortarGreyColor: {
    main: "#9E9E9E",
  },
  hawaiianShellColor: {
    main: "#F2D9D9",
  },
  grapeFruitColor: {
    main: "#FD5A5A",
  },
  brazilianiteColor: {
    main: "#2ECA45",
  },
  celestialCathedralColor: {
    main: "#D8EAF7",
  },
  whiplashColor: {
    main: "#C74646",
  },
  turfMasterColor: {
    main: "#07981E",
  },
  mournMountainSnowColor: {
    main: "#E9E9EA",
  },
  afterMidnightColor: {
    main: "#39393D",
  },
  verdigrisColouredColor: {
    main: "#60BF75",
  },
  petitePinkColor: {
    main: "#EACDCC",
  },
  glowingBrakeDiscColor: {
    main: "#EB4141",
  },
  eliteTealColor: {
    main: "#113138",
  },
  lightSeaCliff: {
    main: "#B9D4E7",
  },
  seaDeepColor: {
    main: "#2D3D45",
  },
  bluesyNoteColor: {
    main: "#7D9EB9",
  },
  serengetiGreenColor: {
    main: "#72CE86",
  },
  blizzardColor: {
    main: "#E4EBEC",
  },
  titaniumBlueColor: {
    main: "#587991",
  },
  fluorescentRedColor: {
    main: "#FE5353",
  },
  oceanViewColor: {
    main: "#749CB4",
  },
  eveningSunsetColor: {
    main: "#EEB16E",
  },
  bluePerennialColor: {
    main: "#BDD8DE",
  },
  rubberRadishColor: {
    main: "#FF9C9C",
  },
  buddhasLoveHandlesColor: {
    main: "#FFB932",
  },
  fullOfLifeColor: {
    main: "#DC612D",
  },
  bigSurBlueJadeColor: {
    main: "#3D708D",
  },
  silverSkateColor: {
    main: "#88A1B5",
  },
  waterColor: {
    main: "#D9F1F9",
  },
  blueLonelinessColor: {
    main: "#486C87",
  },
  bicycleYellowColor: {
    main: "#FFE38D",
  },
};
