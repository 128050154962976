let globalFederationError: string | null = null;

export const captureFederationErrorDescription = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const error = urlParams.get("error_description");
  if (error) {
    globalFederationError = error;
    urlParams.delete("error_description");
    window.history.replaceState({}, "", `\${window.location.pathname}?\${urlParams}`);
  }
};

export const getFederationErrorDescription = (): string | null => {
  return globalFederationError;
};

export const clearFederationErrorDescription = () => {
  globalFederationError = null;
};
