import { useQuery } from "@tanstack/react-query";
import { createContext, useState, useRef, useCallback } from "react";
import { authenticationService } from "../../services/authentication";
import type { CustomCognitoUser } from "../../services/authentication/types";
import { DistributorUserRole } from "../../services/backend_client";
import type { AuthenticationContextProviderProps, AuthenticationContextValue } from "./types";

export const AuthenticationContext = createContext<AuthenticationContextValue | undefined>(undefined);

export const AuthenticationContextProvider = ({ children }: AuthenticationContextProviderProps) => {
  const [user, setUser] = useState<CustomCognitoUser>();
  const [userId, setUserId] = useState<string>("");
  const [schoolId, setSchoolId] = useState<string>("");
  const [distributorId, setDistributorId] = useState<string>("");
  const [role, setRole] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [profileName, setProfileName] = useState<string>("");
  const jwtToken = useRef<string | null>(null);

  const { isLoading: isDeterminingAuthenticationStatus } = useQuery<CustomCognitoUser>(
    ["getAuthenticatedUser"],
    () => authenticationService.getAuthenticatedUser(),
    {
      retry: false,
      onSuccess: async (user) => {
        if (user === undefined) {
          return;
        }
        if (user.attributes !== undefined) {
          setUser(user);
          setUserId(user.attributes["custom:UserId"]);
          if (user.attributes["custom:Role"] === DistributorUserRole.DistributorAdmin || user.attributes["custom:Role"] === DistributorUserRole.Agent) {
            setDistributorId(user.attributes["custom:DistributorId"]);
          } else {
            setSchoolId(user.attributes["custom:SchoolId"]);
          }
          setRole(user.attributes["custom:Role"]);
          setEmail(user.attributes.email);
          setProfileName(user.attributes.name);
        } else {
          const session = await authenticationService.getCurrentSession();
          const payload = session.getIdToken().payload;
          setUser(user);
          setUserId(payload["custom:UserId"]);
          setRole(payload["custom:Role"]);
          if (payload["custom:Role"] === DistributorUserRole.DistributorAdmin || payload["custom:Role"] === DistributorUserRole.Agent) {
            setDistributorId(payload["custom:DistributorId"]);
          } else {
            setSchoolId(payload["custom:SchoolId"]);
          }
          setEmail(payload.email);
          setProfileName(payload.name);
        }
      },
    },
  );

  const resetJwtToken = useCallback(() => {
    jwtToken.current = null;
  }, []);

  const getJwtToken = useCallback(async () => {
    if (jwtToken.current) {
      return jwtToken.current;
    }
    const currentSession = await authenticationService.getCurrentSession();
    const idToken = currentSession.getIdToken();
    const token = idToken.getJwtToken();
    jwtToken.current = token;
    return token;
  }, []);

  const value = {
    isDeterminingAuthenticationStatus,
    user,
    setUser,
    resetJwtToken,
    getJwtToken,
    userId,
    setUserId,
    schoolId,
    setSchoolId,
    distributorId,
    setDistributorId,
    role,
    setRole,
    email,
    setEmail,
    profileName,
    setProfileName,
  };

  return <AuthenticationContext.Provider value={value}>{children}</AuthenticationContext.Provider>;
};
