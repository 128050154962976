import type { CognitoUserSession, CognitoUser } from "amazon-cognito-identity-js";
import type { ClassroomMember, LicenseType, SubmissionItemsListResponse } from "../backend_client";

export interface AuthenticationService {
  getCurrentSession: () => Promise<CognitoUserSession>;
  getCognitoUser: () => CognitoUser;
  getJwtToken: () => Promise<string>;
  resetJwtToken: () => void;
}

export interface BackendService {
  addClassroomMember: ({ ClassroomId, UserId, Role }: AddClassroomMemberParameters) => Promise<void>;
  createClassroom: ({ Name, AccessToCorrectAnswers, ExerciseRepetition, AccessToSpeakingTranscripts }: CreateClassroomParameters) => Promise<Classroom>;
  createPracticeTest: ({ Level, Section }: CreatePracticeTestParameters) => Promise<PracticeTest>;
  createSubmission: ({ ExerciseId, Answers, Candidates }: CreateSubmissionParameters) => Promise<Submission>;
  createUser: ({ Name, Email, Role, AccessToLevels, Classrooms }: CreateUserParameters) => Promise<User>;
  deleteClassroom: ({ ClassroomId }: DeleteClassroomParameters) => Promise<void>;
  deleteClassroomMember: ({ ClassroomId, UserId, Role }: DeleteClassroomMemberParameters) => Promise<void>;
  deleteUser: ({ UserId, Role }: DeleteUserParameters) => Promise<void>;
  getClassroom: ({ ClassroomId }: GetClassroomParameters) => Promise<Classroom>;
  getExerciseById: ({ exerciseId }: GetExerciseByIdParameters) => Promise<ExerciseAttributes>;
  getExerciseByPosition: ({ level, section, part, number, role }: GetExerciseByLocationParameters) => Promise<Exercise>;
  getExerciseCounters: ({ level }: GetExercisesByLevelCode) => Promise<ExercisesPerLevel>;
  getPracticeTest: ({ practiceTestId, userId, role }: GetPracticeTestParameters) => Promise<PracticeTest>;
  getSchoolInfo: ({ schoolId }: GetSchoolInfoParameters) => Promise<School>;
  getSchoolUsers: ({ role }: GetSchoolUsersParameters) => Promise<UsersListResponse>;
  getSubdomain: ({ Name }: SubdomainParameters) => Promise<Subdomain>;
  getSubmission: ({ SubmissionId, UserId, Role }: GetSubmissionParameters) => Promise<Submission>;
  getUser: ({ UserId }: GetUserParameters) => Promise<User>;
  getUserCompletedExercises: ({ UserId, Level }: GetUserCompletedExercises) => Promise<UserCompletedExercisesPerLevel>;
  importUsers: ({ UserList }: ImportUsersParameters) => Promise<ImportResults>;
  listClassroomMembers: ({ ClassroomId }: ListClassroomMembersParameters) => Promise<ClassroomMember[]>;
  listClassrooms: () => Promise<Classroom[]>;
  listMockExamSubmissions: ({ level, userId, role }: ListMockExamSubmissionsParameters) => Promise<MockExamSubmission[]>;
  listPracticeTests: ({ level, section, userId, role }: ListPracticeTestsParameters) => Promise<PracticeTest[]>;
  listSubmissions: ({ level, section, part, userId, role }: ListSubmissionsParameters) => Promise<SubmissionItemsListResponse>;
  listUserClassrooms: ({ UserId, Role }: ListUserClassroomsParameters) => Promise<Classroom[]>;
  schoolSignUp: ({ Name, Email, Password }: SchoolSignUpParameters) => Promise<School>;
  startMockExam: ({ Level, Number }: StartMockExamParameters) => Promise<MockExamSubmission>;
  updateClassroom: ({
    ClassroomId,
    Name,
    AccessToCorrectAnswers,
    ExerciseRepetition,
    AccessToTranscripts,
    BlockedExercises,
    AccessToMockExams,
    AccessToMockExamResults,
  }: UpdateClassroomParameters) => Promise<Classroom>;
  updateMockExamSubmission: ({
    mockExamSubmissionId,
    Section,
    SelectedWriting,
    TimeSpent,
    Status,
    Answers,
    Candidates,
    CompletedListeningParts,
  }: UpdateMockExamSubmissionParameters) => Promise<MockExamSubmission>;
  updatePracticeTest: ({ practiceTestId, TimeSpent, Status, Answers, Candidates }: UpdatePracticeTestParameters) => Promise<PracticeTest>;
  updateSchool: ({
    schoolId,
    Name,
    SchoolSubdomain,
    SchoolLogo,
    SchoolBackground,
    BillingName,
    Country,
    City,
    Address,
    ZipCode,
    TaxId,
  }: UpdateSchoolParameters) => Promise<School>;
  updateUser: ({
    UserId,
    Name,
    Email,
    AccessToLevels,
    Password,
    PermanentPassword,
    AccessToCorrectAnswers,
    AccessToTranscripts,
    ExerciseRepetition,
    BlockedExercises,
    ProfilePicture,
  }: UpdateUserParameters) => Promise<User>;
}

export type BackendError = {
  [message: string]: string;
};

export interface School {
  SchoolId?: string;
  Name?: string;
  SchoolSubdomain?: string;
  SchoolLogo?: string;
  SchoolBackground?: string;
  DistributorId: string | null;
  Currency: string;
  BillingName?: string;
  Country?: string;
  City?: string;
  Address?: string;
  ZipCode?: string;
  TaxId?: string;
}

export interface Subdomain {
  Name: string;
  Logo: string;
  Background: string;
  SchoolName: string;
}

export const enum ImportResult {
  Success = "Success",
  Conflict = "Conflict",
  Error = "Error",
}

export const enum Role {
  SuperAdmin = "SuperAdmin",
  Admin = "Admin",
  Teacher = "Teacher",
  Student = "Student",
  Distributor = "DistributorAdmin",
  DistributorAgent = "Agent",
}

export const enum Currency {
  EUR = "EUR",
  USD = "USD",
}

export type UserPracticeTests = {
  [level: string]: {
    [section: string]: PracticeTest[];
  };
};

export interface PracticeTest {
  PracticeTestId: string;
  UserId?: string;
  Level: string;
  Section: string;
  Duration: number;
  TimeSpent?: { [key: string]: number };
  Exercises: { [key: string]: string };
  Status: PracticeTestStatus;
  Started: string;
  Finished?: string;
  Answers: PracticeTestAnswers;
  Submissions: { [key: string]: string };
  TestScore: { [key: string]: TestScore };
  Candidates?: Array<string>;
  // CorrectAnswers backend type should be [key: string]: string[]
  CorrectAnswers: {
    [key: string]: {
      [key: string]: string;
    };
  };
  AIFeedback: {
    [key: string]: {
      [key: string]: string;
    };
  };
  OverallTestScore?: {
    TestScore: {
      [key: string]: {
        Score: number;
        MaxScore: number;
      };
    };
    CambridgeScore: {
      [key: string]: {
        Score: number;
        MaxScore: number;
      };
    };
    Grade: {
      [key: string]: string;
    };
    CEFRLevel: {
      [key: string]: string;
    };
  };
  CandidatePracticeTestId?: string;
  SelectedWriting?: string;
}

export interface MockExamSubmission {
  MockExamSubmissionId: string;
  UserId?: string;
  Level: string;
  Number: number;
  Duration: { [key: string]: number };
  TimeSpent: {
    [key: string]: {
      [key: string]: number;
    };
  };
  Exercises: {
    [key: string]: {
      [key: string]: string;
    };
  };
  Status: { [key: string]: MockExamSectionStatus };
  Started: string;
  Audios: Array<string>;
  CompletedListeningParts: { [key: string]: boolean };
  Finished?: { [key: string]: string };
  Answers: { [key: string]: MockExamSectionAnswers };
  Submissions: {
    [key: string]: {
      [key: string]: string;
    };
  };
  SelectedWriting?: string;
  Candidates?: Array<string>;
  TestScore: { [key: string]: { [key: string]: TestScore } };
  // CorrectAnswers backend type should be [key: string]: { [key: string]: string[] }
  CorrectAnswers: {
    [key: string]: {
      [key: string]: {
        [key: string]: string;
      };
    };
  };
  AIFeedback: {
    [key: string]: {
      [key: string]: {
        [key: string]: string;
      };
    };
  };
  OverallMockExamScore?: {
    TestScore: {
      [key: string]: {
        Score: number;
        MaxScore: number;
      };
    };
    CambridgeScore: {
      [key: string]: {
        Score: number;
        MaxScore: number;
      };
    };
    Grade: {
      [key: string]: string;
    };
    CEFRLevel: {
      [key: string]: string;
    };
  };
  AverageMockExamScore?: {
    TestScore: {
      Score: number;
      MaxScore: number;
    };
    CambridgeScore: {
      Score: number;
      MaxScore: number;
    };
    Grade: string;
    CEFRLevel: string;
  };
}

export type UserMockExamSubmission = {
  [level: string]: MockExamSubmission[];
};

export const enum PracticeTestStatus {
  Started = "Started",
  Paused = "Paused",
  ToBeCorrected = "ToBeCorrected",
  Finished = "Finished",
}

export const enum MockExamSectionStatus {
  ToBeCompleted = "ToBeCompleted",
  ToBeCorrected = "ToBeCorrected",
  Saved = "Saved",
  Finished = "Finished",
  EarlyFinished = "EarlyFinished",
}

export interface PracticeTestAnswers {
  [key: string]: Map<string, string | Blob>;
}

export type MockExamSectionAnswers = PracticeTestAnswers;

export const enum ExercisesBlockStatus {
  Blocked = "Blocked",
  Unblocked = "Unblocked",
  PartiallyBlocked = "PartiallyBlocked",
}

export interface Exercise {
  ExerciseId: string;
  Level: string;
  Section: string;
  Part: string;
  Number: number;
  Duration: number;
  Active: boolean;
  AIFeedback?: { [key: string]: string };
}

interface Attributes {
  Attributes: object;
}

export interface ExerciseAttributes extends Exercise, Attributes {}

export interface GetExercisesByLevelCode {
  level: string;
  userId?: string;
  role?: string;
}

export interface GetUserCompletedExercises {
  UserId: string;
  Level: string;
}

export interface GetSchoolUsersParameters {
  role: Role;
}

export interface ExercisesPerLevel {
  [SectionCode: string]: {
    [partCode: string]: number;
  };
}

export const enum AnswerCorrection {
  CORRECT = "Correct",
  PARTLY_CORRECT = "PartlyCorrect",
  INCORRECT = "Incorrect",
}

export interface TestScore {
  TestScore: number;
  MaxScore: number;
  CorrectionResults: {
    [answerKey: string]: AnswerCorrection;
  };
  MarkingScheme?: {
    [attribute: string]: number;
  };
}

export interface Submission {
  SubmissionId: string;
  ExerciseId: string;
  UserId: string;
  Answers: Map<string, string | Blob>;
  Candidates?: Array<string>;
  CandidateSubmissions?: Array<string>;
  CreatedAt: string;
  TestScore: TestScore;
  Feedback: string;
  CorrectAnswers: { [key: string]: string };
  AIFeedback: { [key: string]: string };
}

export interface BlockedExercises {
  [levelCode: string]: BlockedExercisesPerLevel;
}

export type BlockedExercisesPerLevel = {
  Status: string;
  BlockedExercises?: UserCompletedExercisesPerLevel;
};

export type LicenseHistory = {
  LicenseId: string;
  LicenseType: LicenseType;
  ValidUntil: string;
  LicenseStarted: string;
  LicenseDuration: string;
};

export type SchoolUsers = {
  [userId: string]: User;
};

export interface User {
  // Evaluate the option to extend ClassroomMember
  UserId: string;
  Name: string;
  Email: string;
  Role: string;
  Active?: boolean;
  Region?: string;
  Created?: string;
  LastAccess?: string;
  AccessToLevels?: string[];
  ProfilePicture?: string;
  LicenseHistory?: LicenseHistory[];
  StartedLevels?: { [key: string]: string[] };
  AccessToMockExams?: AccessToMockExams;
  AccessToFeedback?: boolean;
  AccessToMockExamResults?: AccessToMockExams;
  AccessToCorrectAnswers?: boolean;
  ExerciseRepetition?: boolean;
  AccessToSpeakingTranscripts?: boolean;
  AccessToPracticeTests?: boolean;
  PausePracticeTests?: boolean;
}

export interface UsersListResponse {
  UsersList: Array<User>;
  NextToken: string | null;
}

export interface Student extends User {
  AccessToCorrectAnswers: boolean;
  ExerciseRepetition: boolean;
  AccessToSpeakingTranscripts: boolean;
  BlockedExercises: BlockedExercises;
}

export type UserClassrooms = {
  [classroomId: string]: Classroom;
};

export interface Classroom {
  ClassroomId: string;
  Name: string;
  AccessToCorrectAnswers?: boolean;
  ExerciseRepetition?: boolean;
  AccessToSpeakingTranscripts?: boolean;
  AccessToPracticeTests?: boolean;
  PausePracticeTests?: boolean;
  BlockedExercises?: BlockedExercises;
  ClassroomMembers?: ClassroomMember[];
  AccessToMockExams?: AccessToMockExams;
  AccessToFeedback?: boolean;
  AccessToMockExamResults?: AccessToMockExams;
}
export type AccessToMockExams = {
  [level: string]: number[];
};

export interface SchoolSignUpParameters {
  Name: string;
  Email: string;
  Password: string;
}

export interface SubdomainParameters {
  Name: string;
}

export interface GetSubmissionParameters {
  SubmissionId: string;
  UserId: string;
  Role: string;
}

export interface UpdateSchoolParameters {
  schoolId: string;
  Name?: string;
  SchoolSubdomain?: string;
  SchoolLogo?: string;
  SchoolBackground?: string;
  BlockedExercises?: BlockedExercises;
  BillingName?: string;
  Country?: string;
  City?: string;
  Address?: string;
  ZipCode?: string;
  TaxId?: string;
}

export interface GetExerciseByLocationParameters {
  level: string;
  section: string;
  part: string;
  number: number;
  role: string;
}

export interface APIError {
  message: string;
}

export interface GetExerciseByIdParameters {
  exerciseId: string;
}

export interface GetSchoolInfoParameters {
  schoolId: string;
}

export interface GetExerciseByLevelCode {
  level: string | undefined;
}

export interface GetUserParameters {
  UserId: string;
  Role: string;
}

export interface ListUserClassroomsParameters {
  UserId: string;
  Role: string;
}

export interface CreateUserParameters {
  Name: string;
  Email: string;
  Role: string;
  AccessToLevels?: string[];
  Classrooms?: string[];
}

export interface ImportResults {
  [key: string]: ImportResult;
}

export interface UserToImport {
  Name: string;
  Email: string;
  Role: string;
  ClassroomList: string[];
  Password?: string;
}

export interface ImportUsersParameters {
  UserList: UserToImport[];
}

export interface CreateSubmissionParameters {
  ExerciseId: string;
  Answers: Map<string, string | Blob>;
  Candidates?: Array<string>;
}

export interface UpdateUserParameters {
  UserId: string;
  Role: string;
  Name?: string;
  Email?: string;
  Active?: boolean;
  AccessToLevels?: string[];
  Password?: string;
  PermanentPassword?: boolean;
  AccessToCorrectAnswers?: boolean;
  ExerciseRepetition?: boolean;
  AccessToTranscripts?: boolean;
  BlockedExercises?: BlockedExercises;
  ProfilePicture?: string;
}

export interface UpdateUserBodyParameters {
  Name?: string;
  Email?: string;
  Active?: boolean;
  AccessToLevels?: string[];
  Password?: string;
  PermanentPassword?: boolean;
  AccessToCorrectAnswers?: boolean;
  ExerciseRepetition?: boolean;
  AccessToTranscripts?: boolean;
  BlockedExercises?: BlockedExercises;
  ProfilePicture?: string;
}

export interface DeleteUserParameters {
  UserId: string;
  Role: string;
}

export type UserCompletedExercisesPerLevel = {
  [section: string]: {
    [part: string]: number[];
  };
};

export type UserCompletedExercises = {
  [level: string]: UserCompletedExercisesPerLevel;
};

export type AddClassroomMemberParameters = {
  ClassroomId: string;
  UserId: string;
  Role: string;
};

export type DeleteClassroomMemberParameters = {
  ClassroomId: string;
  UserId: string;
  Role: string;
};

export type CreateClassroomParameters = {
  Name: string;
  AccessToCorrectAnswers: boolean;
  ExerciseRepetition: boolean;
  AccessToSpeakingTranscripts: boolean;
};

export type CreatePracticeTestParameters = {
  Level: string;
  Section: string;
};

export type GetPracticeTestParameters = {
  practiceTestId: string;
  userId?: string;
  role?: string;
};

export type UpdatePracticeTestParameters = {
  practiceTestId: string;
  TimeSpent?: { [key: string]: number };
  Status: PracticeTestStatus;
  Answers: PracticeTestAnswers;
  Candidates?: Array<string>;
};

export type ListPracticeTestsParameters = {
  level: string;
  section?: string;
  userId?: string;
  role?: string;
};

export type ListSubmissionsParameters = {
  level: string;
  section: string;
  part?: string;
  userId?: string;
  role?: string;
};

export type StartMockExamParameters = {
  Level: string;
  Number: number;
};

export type ListMockExamSubmissionsParameters = {
  level: string;
  userId?: string;
  role?: string;
};

export type UpdateMockExamSubmissionParameters = {
  mockExamSubmissionId: string;
  Section: string;
  TimeSpent?: { [key: string]: number };
  Status: MockExamSectionStatus;
  Answers: MockExamSectionAnswers;
  Candidates?: Array<string>;
  SelectedWriting?: string;
  CompletedListeningParts?: { [key: string]: boolean };
};

export type UpdateClassroomParameters = {
  ClassroomId: string;
  Name?: string;
  AccessToCorrectAnswers?: boolean;
  ExerciseRepetition?: boolean;
  AccessToTranscripts?: boolean;
  BlockedExercises?: BlockedExercises;
  AccessToMockExams?: AccessToMockExams;
  AccessToMockExamResults?: AccessToMockExams;
};

export type DeleteClassroomParameters = {
  ClassroomId: string;
};

export type GetClassroomParameters = {
  ClassroomId: string;
};

export type ListClassroomMembersParameters = {
  ClassroomId: string;
};

export interface CognitoUserDataElement {
  Name: string;
  Value: string;
}

export interface CognitoUserData {
  schoolId: string;
  userId: string;
}
